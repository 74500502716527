'use client';

import { createContext, useCallback, useReducer } from 'react';
// ----------------------------------------------------------------------

enum t {
  TOGGLE_OPEN_SIDEBAR = 'TOGGLE_OPEN_SIDEBAR',
  IS_SHOW_HAMBURGER_MENU = 'IS_SHOW_HAMBURGER_MENU',
}

const initialState = {
  isOpenSidebar: false,
  isShowHamburgerMenu: false,
};

type LayoutAction =
  | {
      type: t.TOGGLE_OPEN_SIDEBAR;
    }
  | {
      type: t.IS_SHOW_HAMBURGER_MENU;
      payload: boolean;
    };

const sidebarReducer = (state = initialState, action: LayoutAction) => {
  switch (action.type) {
    case t.TOGGLE_OPEN_SIDEBAR:
      return {
        ...state,
        isOpenSidebar: !state.isOpenSidebar,
      };
    case t.IS_SHOW_HAMBURGER_MENU:
      return {
        ...state,
        isShowHamburgerMenu: action.payload,
      };

    default:
      return { ...state };
  }
};

type LayoutContextType = {
  toggleSidebar: () => void;
  showHamburgerMenu: (isShowHamburgerMenu?: boolean) => void;
  state: typeof initialState;
};

const LayoutContext = createContext<LayoutContextType>({
  state: initialState,
  toggleSidebar: () => {},
  showHamburgerMenu: () => {},
});

// ----------------------------------------------------------------------

type LayoutProviderProps = {
  children: React.ReactNode;
};

function LayoutProvider({ children }: LayoutProviderProps) {
  const [state, dispatch] = useReducer(sidebarReducer, initialState);

  const toggleSidebar = useCallback(() => {
    dispatch({ type: t.TOGGLE_OPEN_SIDEBAR });
  }, []);

  const showHamburgerMenu = useCallback((isShowHamburgerMenu?: boolean) => {
    dispatch({ type: t.IS_SHOW_HAMBURGER_MENU, payload: Boolean(isShowHamburgerMenu) });
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        toggleSidebar,
        showHamburgerMenu,
        state,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
