'use client';

// react
import { Reducer, createContext, useCallback, useReducer } from 'react';
// containers
import DialogContainer from '@/containers/DialogContainer';

enum ReducerActionTypes {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  INITIALIZE = 'INITIALIZE',
}

// ---------------------------------------------------

type DialogVariant = 'info' | 'warning' | 'error' | 'success';

type DialogState = Partial<{
  open: boolean;
  title: string;
  variant: DialogVariant;
  onAccept: () => void;
  onCancel: () => void;
  acceptText: string;
  cancelText: string;
}>;

type DialogAction =
  | {
      type: 'OPEN';
    }
  | {
      type: 'CLOSE';
    }
  | {
      type: 'INITIALIZE';
      payload: DialogState;
    };

type DialogContextType = DialogState & {
  openDialog: (init?: DialogState) => void;
  closeDialog: () => void;
  initialize: (init: DialogState) => void;
};

type DialogProviderProps = {
  children: React.ReactNode;
};

// ---------------------------------------------------------------

const dialogReducer = (state: DialogState, action: DialogAction) => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        open: true,
      };
    case 'CLOSE':
      return {
        ...state,
        open: false,
      };
    case 'INITIALIZE':
      return {
        ...state,
        title: action.payload.title,
        variant: action.payload.variant,
        onAccept: action.payload.onAccept,
        onCancel: action.payload.onCancel,
        acceptText: action.payload.acceptText,
        cancelText: action.payload.cancelText,
      };

    default:
      return state;
  }
};

// ---------------------------------------------------

const DialogContext = createContext<DialogContextType | null>(null);

function DialogProvider({ children }: DialogProviderProps) {
  const initialState = {
    open: false,
    title: '',
    variant: 'info' as DialogVariant,
    onAccept: () => {},
    onCancel: () => {},
    acceptText: 'Accept',
    cancelText: 'Cancel',
  };
  const [state, dispatch] = useReducer<Reducer<DialogState, DialogAction>>(
    dialogReducer,
    initialState
  );

  const closeDialog = useCallback(() => {
    dispatch({
      type: ReducerActionTypes.CLOSE,
    });
  }, []);

  const initialize = useCallback(
    ({ title, variant, onAccept, onCancel, acceptText, cancelText }: DialogState) => {
      dispatch({
        type: ReducerActionTypes.INITIALIZE,
        payload: {
          ...state,
          title: title ? title : state.title,
          variant: variant ? variant : state.variant,
          onAccept: onAccept ? onAccept : state.onAccept ? state.onAccept : closeDialog,
          onCancel: onCancel ? onCancel : state.onCancel ? state.onCancel : closeDialog,
          acceptText: acceptText ? acceptText : state.acceptText,
          cancelText: cancelText ? cancelText : state.cancelText,
        },
      });
    },
    [closeDialog, state]
  );

  const openDialog = useCallback(
    (init?: DialogState) => {
      if (init) initialize(init);

      dispatch({
        type: ReducerActionTypes.OPEN,
      });
    },
    [initialize]
  );

  return (
    <DialogContext.Provider
      value={{
        ...state,
        openDialog,
        closeDialog,
        initialize,
      }}
    >
      <DialogContainer>{children}</DialogContainer>
    </DialogContext.Provider>
  );
}

export type { DialogVariant };

export { DialogContext, DialogProvider };
