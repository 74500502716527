// react
import React, { useContext, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle, Slide, Stack } from '@mui/material';
// components
import Iconify from '@/components/minimal/iconify';
// contexts
import { DialogContext } from '@/contexts/DialogContext';

// ---------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ---------------------------------------------------

const getVariant = (variant) => {
  switch (variant) {
    case 'info':
      return {
        icon: <Iconify icon="akar-icons:info-fill" mr={1} color="secondary.main" fontSize={32} />,
        color: 'secondary',
      };
    case 'error':
      return {
        icon: <Iconify icon="bxs:error-circle" mr={1} color="error.main" fontSize={32} />,
        color: 'error',
      };
    case 'question':
      return {
        icon: <Iconify icon="bi:question-circle" mr={1} color="primary.main" fontSize={32} />,
        color: 'primary',
      };
  }
};

// ---------------------------------------------------

export default function DialogContainer({ children }) {
  const context = useContext(DialogContext);

  const [loading, setLoading] = useState(false);

  if (!context) throw new Error('Dialog context must be use inside DialogProvider');

  const { title, variant, open, onAccept, onCancel, acceptText, cancelText } = context;
  const { icon, color } = getVariant(variant);

  const handleAccept = async () => {
    setLoading(true);
    await onAccept();
    setLoading(false);
  };

  return (
    <>
      {children}
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          sx={{ zIndex: 9999999 }}
          onClose={onCancel}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Stack direction="row" alignItems="center">
              {icon}
              {title}
            </Stack>
          </DialogTitle>
          <DialogActions>
            <Button onClick={onCancel} variant="outlined" color={color}>
              {cancelText}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleAccept}
              variant="contained"
              sx={{ color: 'white' }}
              color={color}
            >
              {acceptText}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
