'use client';

// hooks
import { useAuth } from '@/hooks';
import { useShallow } from '@/hooks/use-shallow';
// react
import { useEffect, useMemo } from 'react';

type ParamCheckerContainerProps = {
  children: React.ReactNode;
};

export default function ParamCheckerContainer({ children }: ParamCheckerContainerProps) {
  const { user } = useAuth();

  const { query } = useShallow();

  const referrer = useMemo(() => query?.referrer, [query]);
  const cpayer = useMemo(() => query?.cpayer, [query]);
  const utmSource = useMemo(() => query?.utm_source, [query]);
  const utmMedium = useMemo(() => query?.utm_medium, [query]);

  useEffect(() => {
    if (!isNaN(referrer as any) && Number(referrer) !== user?.id) {
      sessionStorage.setItem('referrer-user', referrer ?? '');

      if (!isNaN(cpayer as any) && Number(cpayer) !== user?.id) {
        sessionStorage.setItem('referrer-commission-payer', cpayer ?? '');
      } else {
        sessionStorage.removeItem('referrer-commission-payer');
      }
    }
    if (utmSource) {
      sessionStorage.setItem('referrer-traffic-source', utmSource ?? '');
    }
    if (utmMedium) {
      sessionStorage.setItem('referrer-traffic-medium', utmMedium ?? '');
    }
  }, [cpayer, query, referrer, user?.id, utmMedium, utmSource]);

  return <>{children}</>;
}
