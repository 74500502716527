'use client';

import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, ThemeOptions } from '@mui/material/styles';
// locales
import { useLocales } from '@/locales';
// lodash
import merge from 'lodash.merge';
// hooks
import { useSettings } from '../hooks';
//
import { palette } from './palette';
import { typography } from './typography';
import { componentsOverrides } from './overrides';
import { shadows } from './shadows';
import NextAppDirEmotionCacheProvider from './next-emotion-cache';
import { customShadows } from './custom-shadows';
import { darkMode } from './options/dark-mode';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { currentLang } = useLocales();
  const { themeMode } = useSettings();

  const darkModeOption = darkMode(themeMode);

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      typography,
      shape: { borderRadius: 8 },
    }),
    []
  );

  const memoizedValue = useMemo(
    () => merge(baseOption, darkModeOption),
    [baseOption, darkModeOption]
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = componentsOverrides(theme);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'css' }}>
      <MUIThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
