'use client';

// redux
import { useSelector } from '@/redux/store';
// next
import dynamic from 'next/dynamic';
// modals
import LoadingModal from '@/modals/LoadingModal';
const loading = () => <LoadingModal />;
const LoginModal = dynamic(() => import('@/modals/LoginModal'), {
  loading,
});
const SignupModal = dynamic(() => import('@/modals/SignupModal'), {
  loading,
});
const CreateProfileModal = dynamic(() => import('@/modals/CreateProfileModal'), {
  loading,
});
const ViewSubmissionModal = dynamic(() => import('@/modals/ViewSubmissionModal'), {
  loading,
});
const EventsModal = dynamic(() => import('@/modals/EventsModal'), {
  loading,
});
const RegisteredUsersModal = dynamic(() => import('@/modals/RegisteredUsersModal'), {
  loading,
});
const AffiliateUsersModal = dynamic(() => import('@/modals/AffiliateUsersModal'), {
  loading,
});
const StartupViewSubmissionModal = dynamic(() => import('@/modals/StartupViewSubmissionModal'), {
  loading,
});
const CreateInvestorModal = dynamic(() => import('@/modals/CreateInvestorModal'), {
  loading,
});
const AddTeamMemberModal = dynamic(() => import('@/modals/AddTeamMemberModal'), {
  loading,
});
const ApplyToPitchModal = dynamic(() => import('@/modals/ApplyToPitchModal'), {
  loading,
});
const UserGroupPageBuilder = dynamic(() => import('@/modals/UserGroupPageBuilder'), {
  loading,
});
const CreateStartupModal = dynamic(() => import('@/modals/CreateStartupModal'), {
  loading,
});
const ProgramsModal = dynamic(() => import('@/modals/ProgramsModal'), {
  loading,
});
const CreateSMEModal = dynamic(() => import('@/modals/CreateSMEModal'), {
  loading,
});
const CreateIncubatorModal = dynamic(() => import('@/modals/CreateIncubatorModal'), {
  loading,
});
const ServicesModal = dynamic(() => import('@/modals/ServicesModal'), {
  loading,
});
const CohortsModal = dynamic(() => import('@/modals/CohortsModal'), {
  loading,
});
const CalendlyScheduleMeetingModal = dynamic(
  () => import('@/modals/CalendlyScheduleMeetingModal'),
  { loading }
);
const PaymentModal = dynamic(() => import('@/modals/PaymentModal'), {
  loading,
});
const SelectStartupDialog = dynamic(() => import('@/modals/SelectStartupDialog'), {
  loading,
});
const ProductModal = dynamic(() => import('@/modals/ProductModal'), {
  loading,
});
const CreateInstitutionModal = dynamic(() => import('@/modals/CreateInstitutionModal'), {
  loading,
});
const RateModal = dynamic(() => import('@/modals/RateModal'), {
  loading,
});
// react-google-recaptcha-v3
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// @react-oauth/google
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function ModalContainer() {
  const state = useSelector((state) => state.modal);
  return (
    <>
      {state.login.open && (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
            <LoginModal {...state.login} />
          </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
      )}
      {state.signup.open && (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
            <SignupModal {...state.signup} />
          </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
      )}

      {state.createProfile.open && <CreateProfileModal {...state.createProfile} />}
      {state.viewSubmission.open && <ViewSubmissionModal {...state.viewSubmission} />}
      {state.events.open && <EventsModal {...state.events} />}
      {state.cohorts.open && <CohortsModal {...state.cohorts} />}
      {state.programs.open && <ProgramsModal {...state.programs} />}
      {state.services.open && <ServicesModal {...state.services} />}
      {state.product.open && <ProductModal {...state.product} />}
      {state.calendlyScheduleMeeting.open && (
        <CalendlyScheduleMeetingModal {...state.calendlyScheduleMeeting} />
      )}
      {state.registeredUsers.open && <RegisteredUsersModal {...state.registeredUsers} />}
      {state.affiliateUsers.open && <AffiliateUsersModal {...state.affiliateUsers} />}
      {state.startupViewSubmission.open && (
        <StartupViewSubmissionModal {...state.startupViewSubmission} />
      )}
      {state.createInvestor.open && <CreateInvestorModal {...state.createInvestor} />}
      {state.addTeamMember.open && <AddTeamMemberModal {...state.addTeamMember} />}
      {state.applyToPitch.open && <ApplyToPitchModal {...state.applyToPitch} />}
      {state.userGroupPageBuilder.open && <UserGroupPageBuilder {...state.userGroupPageBuilder} />}
      {state.payment.open && <PaymentModal {...state.payment} />}
      {state.selectStartup.open && <SelectStartupDialog {...state.selectStartup} />}
      {state.createIncubator.open && <CreateIncubatorModal {...state.createIncubator} />}
      {state.createSME.open && <CreateSMEModal {...state.createSME} />}
      {state.createStartup.open && <CreateStartupModal {...state.createStartup} />}
      {state.createInstitution.open && <CreateInstitutionModal {...state.createInstitution} />}
      {state.rate.open && <RateModal {...state.rate} />}
    </>
  );
}
