import { useRouter } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

export type UseShallowPushFunction = (url: string, options?: PushOptions) => void;

type ReturnType = { query: any; push: UseShallowPushFunction };

type QueryType = URLSearchParams | string | Record<string, string>;

type PushOptions = {
  query?: QueryType;
  changeLocation?: boolean;
};

export const useShallow = (): ReturnType => {
  const emptyQuery = useMemo(() => ({}), []);
  const [query, setQuery] = useState<{ [k: string]: string }>(emptyQuery);

  const { push: nextPush } = useRouter();

  const push = (url: string, options?: PushOptions) => {
    if (options?.query) {
      const newQuery = new URLSearchParams(options.query);
      url = `${url}?${newQuery.toString()}`;
      history.pushState(options.query, '', url);
    } else {
      history.pushState(query, '', url);
    }

    if (options?.changeLocation) {
      nextPush(url);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setQuery(Object.fromEntries(new URLSearchParams(window.location.search)));
    } else {
      setQuery(emptyQuery);
    }
  }, [emptyQuery]);

  useEffect(() => {
    // make a copy of original function to avoid complications
    const originalPushState = history.pushState;

    history.pushState = function (data, title, url) {
      originalPushState.apply(history, [data, title, url]);
      setQuery(data);
    };

    return () => {
      history.pushState = originalPushState; // restore the copy
    };
  }, []);

  return { query, push };
};
