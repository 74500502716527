'use client';

// react
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import { IconButton, Popover, Stack, Tooltip, Typography } from '@mui/material';
// constants
import { OPEN_SHARE_OPTIONS_EVENT } from '@/constants/window-events/open-share-options.window-event.constant';
// components
import Iconify from '@/components/minimal/iconify';
// next
import dynamic from 'next/dynamic';
//
const MoreLinksDialog = dynamic(() => import('./MoreLinksDialog'));

// ---------------------------------------------------

const facebookBaseUrl = 'https://www.facebook.com/sharer/sharer.php';
const twitterBaseUrl = 'https://twitter.com/intent/tweet';
const linkedinBaseUrl = 'https://www.linkedin.com/sharing/share-offsite';

// ---------------------------------------------------

type SocialMediaIconButtonProps = {
  iconColor: 'facebook' | 'twitter' | 'linkedin' | 'otherlink';
  tooltipTitle?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const SocialMediaIconButton = ({
  iconColor,
  children,
  tooltipTitle = '',
  ...other
}: SocialMediaIconButtonProps) => (
  <Tooltip title={tooltipTitle} PopperProps={{ sx: { zIndex: 9999 } }}>
    <span>
      <IconButton
        {...other}
        sx={{
          backgroundColor: (theme) => theme.palette.social[iconColor],
          color: (theme) => theme.palette.grey[200],
          transition: 'all .5s',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.grey[200],
            color: (theme) => theme.palette.social[iconColor],
          },
        }}
      >
        {children}
      </IconButton>
    </span>
  </Tooltip>
);

// ---------------------------------------------------

export default function ShareOptionsContainer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreLinksOpen, setMoreLinksOpen] = useState(false);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const [sharedUrl, setSharedUrl] = useState<URL>();

  useEffect(() => {
    function showPopover(event: any) {
      setAnchorEl(event?.detail?.target);
      setSharedUrl(event?.detail?.sharedUrl);
    }
    document.addEventListener(OPEN_SHARE_OPTIONS_EVENT, showPopover);
    return () => document.removeEventListener(OPEN_SHARE_OPTIONS_EVENT, showPopover);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleShareOnFacebook = useCallback(() => {
    const url = new URL(facebookBaseUrl);

    sharedUrl?.searchParams.set('utm_source', 'facebook');
    sharedUrl?.searchParams.set('utm_medium', 'social');

    url.searchParams.set('u', sharedUrl?.toString() ?? '');
    window.open(url, '_blank');
  }, [sharedUrl]);

  const handleShareOnTwitter = useCallback(() => {
    const url = new URL(twitterBaseUrl);

    sharedUrl?.searchParams.set('utm_source', 'twitter');
    sharedUrl?.searchParams.set('utm_medium', 'social');

    url.searchParams.set('url', sharedUrl?.toString() ?? '');
    window.open(url, '_blank');
  }, [sharedUrl]);

  const handleShareOnLinkedin = useCallback(() => {
    const url = new URL(linkedinBaseUrl);

    sharedUrl?.searchParams.set('utm_source', 'linkedin');
    sharedUrl?.searchParams.set('utm_medium', 'social');

    url.searchParams.set('url', sharedUrl?.toString() ?? '');
    window.open(url, '_blank');
  }, [sharedUrl]);

  const handleOpenMoreLinksDialog = useCallback(() => {
    setMoreLinksOpen(true);
  }, []);

  return (
    <>
      {moreLinksOpen && (
        <MoreLinksDialog open={moreLinksOpen} setOpen={setMoreLinksOpen} sharedUrl={sharedUrl} />
      )}
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          PaperProps={{
            sx: { p: 2 },
          }}
        >
          <Typography variant="subtitle1">Share with:</Typography>
          <Stack direction="row" spacing={2} p={2}>
            <SocialMediaIconButton
              onClick={handleShareOnLinkedin}
              iconColor="linkedin"
              tooltipTitle="Linkedin"
            >
              <Iconify icon="akar-icons:linkedin-fill" />
            </SocialMediaIconButton>
            <SocialMediaIconButton
              onClick={handleShareOnTwitter}
              iconColor="twitter"
              tooltipTitle="Twitter"
            >
              <Iconify icon="akar-icons:twitter-fill" />
            </SocialMediaIconButton>
            <SocialMediaIconButton
              iconColor="facebook"
              tooltipTitle="Facebook"
              onClick={handleShareOnFacebook}
            >
              <Iconify icon="akar-icons:facebook-fill" />
            </SocialMediaIconButton>
            <SocialMediaIconButton
              iconColor="otherlink"
              tooltipTitle="Copy Link"
              onClick={handleOpenMoreLinksDialog}
            >
              <Iconify icon="akar-icons:link-chain" />
            </SocialMediaIconButton>
          </Stack>
        </Popover>
      )}
    </>
  );
}

export function openShareButton(e: any, sharedUrl: URL) {
  const openShareOptionsEvent = new CustomEvent(OPEN_SHARE_OPTIONS_EVENT, {
    detail: { target: e.currentTarget, sharedUrl },
  });
  document.dispatchEvent(openShareOptionsEvent);
}
