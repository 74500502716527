// @mui
import { Box, Modal } from '@mui/material';
// components
import { Loading } from '@/components/common';
//

const style = {
  width: { xs: '100%', md: '500px' },
  height: '400px',
  borderRadius: '20px',
  bgcolor: '#FFFFFF',
  p: 1,
  mb: 50,
  textAlign: 'left',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
};
const styles = {
  paddingTop: '3%',
  overflowX: 'hidden',
};

export default function LoadingModal() {
  return (
    <Modal open={true} sx={styles}>
      <Box sx={style}>
        <Loading />
      </Box>
    </Modal>
  );
}
